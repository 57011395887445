import React, { Suspense, lazy } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import ScrollToTop from './components/ScrollTop';

// Lazy load the pages
const HomePage = lazy(() => import('./pages/HomePage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const ProductDetailPage = lazy(() => import('./pages/ProductDetailPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const PrivacyPage = lazy(() => import('./pages/PrivacyPage'));
const TermsAndCondition = lazy(() => import('./pages/TermsAndCondition'));
const ListPage = lazy(() => import('./pages/ListPage'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    errorElement: <NotFoundPage />, 
  },
  {
    path: '/two-wheelers-in-bengaluru',
    element: <HomePage />,
  },
  {
    path: '/about',
    element: <AboutPage />,
  },
  {
    path: '/bikes',
    element: <ListPage />,
  },
  {
    path: '/second-hand-bikes-in-bangalore',
    element: <ListPage />,
  },
  {
    path: '/detail/:id',
    element: <ProductDetailPage />,
  },
  {
    path: '/privacy',
    element: <PrivacyPage />,
  },
  {
    path: '/tnc',
    element: <TermsAndCondition />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={
        <Box width={'100vw'} display={'flex'} justifyContent={'center'} pt={5}>
          <CircularProgress />
        </Box>
        }>
        <RouterProvider router={router}>
          <ScrollToTop />
        </RouterProvider>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
